.meal-options-layout-container {
  min-width: 25rem;
  width: 100%;
  margin-right: 1.5rem;
  height: 100%;
  padding: 1rem;
  // overflow-y: scroll;
  background: rgba(211, 232, 255, 0.2);
  border: 1px solid #d3e8ff;
}

.fare-summary-box-st {
  min-width: 25rem;
  min-height: 7rem;
  flex-direction: column !important;

  .fee-surcharge-div {
    width: 100%;
    height: 2.5rem;
    line-height: 2.5rem;
    background-color: #f9f9fa;
  }
}
