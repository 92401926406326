@import "../../../theme/variables.scss";

.container-passenger-info-page {
  .main-passenger-info {
    width: 90%;
    max-width: 80rem;
  }

  .btn-cancel {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    width: 9rem;
    height: 3rem;
    background-color: $whiteColor;
    color: #787885;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    font-family: "Gotham Rounded";
  }

  .btn-contn-paymnt {
    background: #b62079;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: none;
    color: $whiteColor;
    height: 3rem;
    width: 15rem;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}

.forms-divider {
  margin: 1.5rem auto !important;
}

.react-tel-input .form-control {
  width: 100%;
  height: 40px;
}

@media screen and (min-width: 640px) {
  .wheelChair_assistence {
    justify-content: center;
  }
  .add_new_user {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 640px) {
  .wheelChair_assistence,
  .add_new_user {
    justify-content: flex-start;
    margin-top: 10px;
  }
}
