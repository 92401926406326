@import "./theme/variables.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // font-family: "Gotham Rounded" !important;
}

@font-face {
  font-family: "Gotham Rounded Book";
  src: url("./Gothem/GothamRounded-Book.woff2") format("woff2"),
    url("./Gothem/GothamRounded-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GothamBold";
  src: url("./Gothem/GothamRounded-Bold.woff2") format("woff2"),
    url("./Gothem/GothamRounded-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("./Gothem/GothamRounded-Light.woff2") format("woff2"),
    url("./Gothem/GothamRounded-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("./Gothem/GothamRounded-Medium.woff2") format("woff2"),
    url("./Gothem/GothamRounded-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.MuiPaper-elevation {
  box-shadow: none !important;
}
