@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url(./Gothem/GothamRounded-Medium.woff2) format("woff");
}

@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url(./Gothem/GothamRounded-Medium.woff2) format("woff");
}

@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url(./Gothem/GothamRounded-Medium.woff2) format("woff");
}

@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url(./Gothem/GothamRounded-Medium.woff2) format("woff");
}
* {
  font-family: "GothamBook", serif;
}
