.accordianContainer {
  box-shadow: unset !important;
  margin: 24px 0px;

  .summary {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
  }
  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
  }
}

.accordianWrapper {
  border-radius: 66%;
  border: 1px solid;
  width: 24px;
  height: 24px;
  text-align: center;
  color: #b62079;
}

.faq-form {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
  /* phones */
  .faq-form {
    width: 100%;
  }
}
