@import "../../../../theme/variables.scss";

.header-contact-declaration-form {
  height: 2rem;
  background-color: $pink200;
  border: 1px solid $pink700;
  .indexNo {
    display: inline-block;
    width: 4rem;
    padding: 0 1rem;
  }
}

.react-tel-input .form-control {
  width: 100%;
  height: 40px;
}
