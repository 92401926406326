@import "../../../../theme/variables.scss";

header {
  .sub-nav {
    margin: auto;
    height: 4.8rem;
    background: #ffedf8;

    .top-nav-step-fig {
      margin-left: -60px;
      .arrow-svg-img {
        width: 85%;
        height: 5.6rem;
        margin-top: 0.3rem;
      }
      figcaption {
        top: 50%;
        left: 40%;
        transform: translate(-50%, -40%);
      }
    }
  }
}

#select-flight {
  color: $whiteColor;
}

// @media screen and (min-width: 1200px) {
// }
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) {
  .sub-nav {
    padding-left: 10% !important;
  }
}
