.signupform-container {
  max-width: 80rem;
}

.btn-cancel-signup {
  width: 8rem;
  height: 2.5rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: #787885;
}

.btn-finish-signup {
  width: 9rem;
  height: 2.5rem;
  border-radius: 4px;
}

.btn-group-signup {
  align-self: end;
  justify-self: end;
}
