table {
  border-collapse: collapse;
}
td,
th {
  border: 1px solid rgba(0, 0, 0, 0.12);
  text-align: left;
  padding: 1rem;
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
}
