@import "../../../theme/variables.scss";

.container-review-page {
  main {
    width: 90%;
    max-width: 80rem;
    .review-your-flight {
      .btn-frqnt-flyr-login {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        border: none;
        width: 15rem;
      }
    }

    .bottom-buttons {
      .btn-cancel {
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        width: 9rem;
        height: 3rem;
        background-color: $whiteColor;
        color: #787885;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        font-family: "Gotham Rounded";
      }

      .btn-add-psngr-dtls {
        background: #b62079;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        border: none;
        color: $whiteColor;
        height: 3rem;
        width: 15rem;
      }
    }
  }
}
.flight-details-review {
  max-width: 750px;
  height: 515px;
  flex-direction: column !important;

  .flight-details-review-header {
    width: 100%;
    height: 2.2rem;
    line-height: 2.2rem;
    background-color: #b62079;
  }
  .fldtls {
    height: 70%;
    position: relative;

    .border-line-custom {
      .line {
        display: block;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.25);
        width: 1px;
        height: 70%;
        top: 18%;
        left: 2%;
      }

      .dot-before {
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: $primaryColor;
        border-radius: 50%;
        top: 16%;
        left: 1.4%;
      }

      .dot-after {
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: $primaryColor;
        border-radius: 50%;
        bottom: 10%;
        left: 1.312%;
      }
    }
  }
  .flight-details-review-footer {
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    background: rgba(196, 196, 196, 0.2);
  }
}

.fare-summary {
  min-height: 20rem;
  height: 100%;
  flex-direction: column !important;

  .base-fare-div,
  .fee-surcharge-div,
  .total-amount-div {
    width: 100%;
    height: 2.5rem;
    line-height: 2.5rem;
    background-color: #f9f9fa;
  }

  .savings-txt {
    color: $green500;
  }
}

.review-citycode {
  width: 3.2rem;
  height: 1.5rem;
  // padding-left: 0.5%;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  line-height: 1.5rem;
  margin-bottom: 0.4rem;
  background-color: $blue500;
  text-align: center;
}
.review-cityname {
  color: $blue500;
}
