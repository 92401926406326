.calender_scroll {
  border-right: 1px solid #bac9d9;
  &:last-child {
    border: none;
  }
}

.flight_card {
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4px 5.25905px rgba(26, 56, 97, 0.04);
  margin-top: 10px;
}

.search_card_type {
  .MuiFormControlLabel-root {
    margin: 0px !important;
  }
  .MuiFormControlLabel-root {
    flex-direction: row-reverse !important;
  }
}
