@import "../../../theme/variables.scss";

.navbar-home {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.header-right {
  display: flex;
  align-items: baseline;

  .logo {
    width: 8.75rem;
    height: 2.813rem;
    margin-right: 3.125rem;
  }

  .nav-links a {
    margin-right: 1rem;
    font-size: 1rem;
    font-weight: 500;
  }
}

.auth-signs {
  font-size: 1rem;
  font-weight: 500;
}

.navbar-search {
  margin: auto;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 1rem;
  background-color: #b62079;
  // background: url("../../../assets/headerBg.jpeg");
  background-size: cover;

  a {
    text-decoration: none;
    color: $whiteColor;
    font-size: 1rem;
    font-weight: 350;
  }
}

.profile-popover {
  min-width: 15rem;
  min-height: 2.5rem;
  padding: 0.8rem;
  margin: auto;
  background-color: $whiteColor;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  top: 120%;
  ul {
    list-style: none;
    margin: auto;
  }
}

.profile-popover::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
