@import "../../../../theme/variables.scss";

.arrow-svg {
  width: 35px;
  height: 35px;
  border: 1px solid $grayBorderColor;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: $whiteColor;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.divider {
  height: 70%;
  width: 1px;
  position: absolute;
  background-color: $gray500;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.border-top {
  border-top: 1px solid $gray500;
}

.border-bottom {
  border-bottom: 1px solid $gray500;
}

.box-shadow {
  box-shadow: 0px 1.85px 6.25px rgba(0, 0, 0, 0.19);
}

.mobile_search_dropdown_container {
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .MuiSelect-select {
    padding-left: 0px !important;
  }
}

.search_location_input {
  border: none;
  outline: none;
  width: 100%;
  height: 17px;
  font-size: 17px;
}

.calender_clear_return {
  position: absolute;
  top: -7px;
  right: -7px;
  color: grey;
  height: 18px !important;
  width: 18px !important;
}

.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0px;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content p:hover {
  background-color: #ddd;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

.login_logo {
  height: 35px;
  width: 35px;
}

.mobile-home-nav {
  // background: url("../../../../assets/headerBg.jpeg");
  background-color: #b62079;
  background-size: cover;
}
