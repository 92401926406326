@import "../../../theme/stylesheet.scss";

.container-seat-map-desk {
  .flight-details-box {
    max-width: 80rem;
    height: 14rem;

    .flight-details-header {
      width: 100%;
      height: 3rem;
      line-height: 3rem;
    }

    .flght-dtls-cross-svg-section {
      margin-top: 1rem;
      .border-line {
        .line {
          display: block;
          position: absolute;
          background-color: rgb(0, 0, 0);
          height: 1px;
          width: 75%;
          left: 8%;
        }
        .flght-dtls-cross-svg {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .dot-before {
          display: block;
          width: 10px;
          height: 10px;
          background-color: $primaryColor;
          border-radius: 50%;
          top: -5px;
          left: 8%;
          z-index: 1;
        }

        .dot-after {
          display: block;
          width: 10px;
          height: 10px;
          background-color: $primaryColor;
          border-radius: 50%;
          top: -5px;
          right: 17%;
          z-index: 1;
        }
      }
    }
  }

  .selected-seat-info-box {
    min-width: 15rem;
    height: 100%;
    margin-right: 1rem;
    .selected-seat-heading {
      background: rgba(211, 232, 255, 0.2);
      border-bottom: 1px solid #d3e8ff;
      height: 2.5rem;
      padding: 0.5rem;
    }
    .user-selecting-seat {
      background: #f8f8f8;
      height: 100%;
      border-bottom: 1px solid $grayBorderColor;
      .uss-info-tab {
        width: 70%;
        height: 2.5rem;
        background-color: #1eaf28;
      }
    }
  }

  .seat-map-layout-container {
    min-width: 25rem;
    width: 100%;
    margin-right: 1.5rem;

    .seat-map-info {
      height: 5rem;
      margin-bottom: 2rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    }

    .seat-map-layout-box {
      height: 100%;
      max-height: 33rem;
      padding: 0 4rem;
      overflow-y: scroll;
      background: rgba(211, 232, 255, 0.2);
      border: 1px solid #d3e8ff;
    }
  }

  .fare-summary-box {
    min-width: 25rem;
    min-height: 25rem;
    flex-direction: column !important;

    .base-fare-div,
    .fee-surcharge-div,
    .total-amount-div {
      width: 100%;
      height: 2.5rem;
      line-height: 2.5rem;
      background-color: #f9f9fa;
    }
  }

  .btn-group-seatmap {
    .btn-skip {
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      width: 9rem;
      height: 3rem;
      background-color: $whiteColor;
      color: #787885;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      font-family: "Gotham Rounded";
    }

    .btn-continue {
      background: #b62079;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      border: none;
      color: $whiteColor;
      height: 3rem;
      width: 15rem;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }
}
