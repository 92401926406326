@import "../../../theme/variables.scss";

@media screen and (max-width: 640px) {
  .DayPicker-Month {
    width: 99%;
    margin: 0px !important;
    margin-top: 0.5rem !important;
  }
  .DayPicker-Caption > div {
    font-size: 1rem !important;
    font-weight: 700 !important;
  }
  .DayPicker-Body {
    margin: 0px 16px !important;
  }
  .DayPicker-Weekdays {
    display: none !important;
  }
  .DayPicker-Month:first-child .DayPicker-Weekdays {
    display: table-header-group !important;
    position: fixed !important;
    width: 100%;
    top: 40px;
    background-color: $primaryColor !important;
    z-index: 100;
  }
  .DayPicker-WeekdaysRow {
    display: flex !important;
    width: 100%;
    flex: 1;
    justify-content: space-around !important;
    div > abbr {
      color: #fff !important;
      content: "Sun" !important;
    }
  }

  .DayPicker-Day--today {
    color: black;
    font-weight: 400;
  }

  .DayPicker-Day--disabled {
    color: #dce0e0;
    & > div > div:nth-child(2) {
      display: none !important;
    }
  }

  .DayPicker-Day--today.DayPicker-Day--disabled {
    color: #dce0e0;
    & > div:nth-child(2) {
      display: none !important;
    }
  }

  .DayPicker-Month:first-child .DayPicker-Caption {
    margin-top: 30px;
  }
  .DayPicker-Day--outside > div {
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: #dce0e0 !important;
    & > div:nth-child(2) {
      display: none !important;
    }
  }
}

.Range
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: $primary100 !important;
  color: black;
}
.Range .DayPicker-Day {
  border-radius: 0 !important;
}

.DayPicker-Day--start,
.DayPicker-Day--end {
  background-color: #b62079 !important;
  color: white !important;
}

.DayPicker-Day--outside.DayPicker-Day--start,
.DayPicker-Day--outside.DayPicker-Day--end {
  background: transparent !important;
  color: #dce0e0 !important;
}
