.step-three-container {
    width: 80% !important;
    height: 90%;
    min-height: 30rem;
    padding:20px;
  
    .step-three-banner {
      width: 100%;
      overflow-y: auto;
      height: 100%;
      background-size: cover;
    }
  
  }
  