@import "../../../../theme/variables.scss";

.my-booking-itinerary-container {
  .itinerary-details-wrapper {
    width: 80%;
    min-height: 20rem;
    height: 100%;
  }
  .fare-details-my-booking {
    width: 25rem;
  }
}

.cancel-modal {
  width: 20rem;
  height: 15rem;
  background-color: $whiteColor;
}

.srno-column {
  width: 10%;
}
