@import "../../../../../theme/variables.scss";

.auto-input-class-ref {
  opacity: 0;
  width: 100%;
  margin-top: -50px;
}
ul::-webkit-scrollbar {
  width: 10px;
}
ul {
  scrollbar-width: thin;
}
ul::-webkit-scrollbar-track {
  background: $gray550;
}
ul::-webkit-scrollbar-thumb {
  background-color: $primaryColor;
  border-radius: 6px;
  border: 1px solid $gray550;
}

#simple-popover-passenger {
  position: absolute;
  top: 40%;
  left: 65%;
  height: 19rem;
  max-width: 20rem;
}

.flight-booking-tabs {
  background: $whiteColor;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0.625rem 0.625rem 0.625rem;
  position: relative;
  height: 17rem;

  .selections {
    height: 100%;
    min-height: 7.188rem;

    .locations {
      width: 100%;
      margin-right: 5px;
      display: flex;
      position: relative;

      .departure,
      .arrival {
        width: 100%;
        height: 7.6rem;
        border: 1px solid $grayBorderColor;
        padding: 1rem;
        border-radius: 4px 4px 4px 4px;

        & img {
          margin-right: 0.5rem;
        }

        .selected-city-styled {
          width: 100%;
          align-items: center;
          margin-bottom: 5px;

          .city-name {
            font-weight: 350;
            font-size: 25px;
          }
          .city-code {
            padding-top: 2px;
            margin-left: 10px;
            width: 4rem;
            height: 1.8rem;
            font-weight: 350;
            font-size: 25px;
            line-height: 25px;
            border-radius: 2px;
            text-align: center;
            vertical-align: middle;
          }
        }
      }
      .departure {
        border-right: none;
      }

      .arrow-svg {
        width: 30px;
        height: 30px;
        border: 1px solid $grayBorderColor;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: $whiteColor;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      #auto-complete-departure,
      #auto-complete-arrival {
        height: 3rem;
      }
    }

    .depart-return-date-select {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-radius: 4px;
      // max-width: 26.188rem;
      min-height: 7.188rem;
      border: 1px solid $grayBorderColor;
      margin-right: 5px;

      .depart-date-select,
      .return-date-select {
        padding: 10px;
        width: 50%;
        position: relative;

        #calender-select-depart,
        #calender-select-return {
          height: 3rem;
        }
      }
      .depart-date-select {
        border-right: 1px solid $grayBorderColor;
      }
      .return-date-select-disabled {
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.12);
      }
    }

    .passengers {
      width: 100%;
      // max-width: 26.188rem;
      min-height: 7.188rem;
      border: 1px solid $grayBorderColor;
      position: relative;
      border-radius: 4px;

      .passenger-input {
        padding: 16px;
        #passanger-selection-input {
          height: 3rem;
        }
      }
    }
  }
}

.passenger-selection {
  width: 20rem;
  padding: 10px;
  .passenger-adult,
  .passenger-childern,
  .passenger-infant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    .passenger-age-info {
      .age-category {
        font-size: 14px;
        line-height: 17px;
        font-weight: 700;
      }
      .age-range {
        color: rgb(114, 106, 106);
      }
    }

    .btn-div-passenger-qty {
      width: 35%;
    }
  }

  .btn-done-passenger {
    width: 90px;
    height: 40px;
    background-color: $primaryColor;
    border-radius: 5px;
    border: none;
    color: $whiteColor;
    margin-left: 12rem;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
  }
}

.city-selection {
  padding: 10px;
  p {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .airport-name {
    font-weight: 400;
    font-size: 12px;
  }
}

.city-name {
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
}

.city-code {
  background-color: #010101;
  color: $whiteColor;
  width: 2.6rem;
  height: 1.1rem;
  font-weight: 500;
  font-size: 15px;
  border-radius: 2px;
  text-align: center;
  vertical-align: middle;
  .custom-input {
    width: 100%;
    height: 4rem;
  }
}

#simple-popover-calender-depart,
#simple-popover-calender-return {
  position: absolute;
  top: 50% !important;
  left: 40% !important;
}
