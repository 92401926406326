$primaryColor: #b62079;
$primary900: #6e1048;
$primary700: #6941c6;
$primary100: #fff6ff;
$primary200: #fff1f1;
$grayColor: #787885;
$gray100: #f8f8f8;
$gray200: #f2f2f2;
$gray300: #f1f1f1;
$gray800: #545454;
$gray400: #c3cbcd;
$gray500: #d2d2d2;
$gray550: #cfd8dc;
$gray600: #979797;
$gray700: #787885;
$gray900: #667085;
$whiteColor: #ffffff;
$green100: #00ae0c26;
$green200: #caffdf;
$green500: #00b00c;
$green600: #00ae0c;
$green700: #1eaf28;
$green900: #027a48;
$black400: #262d33;
$blackColor: #000000;
$link500: #4285f4;
$grayBorderColor: #d1d1d1;
$boxShadowColor: #000000;
$footerColor: #e3e5e8;
$orange100: #f392001f;
$orangeColor: #d92d20;
$pinkBgColor: #ff7272;
$gray250: #f4f4f4;
$pink200: #ffedf8;
$red100: #fef3f2;
$red900: #b42318;
$pink700: #f6b4e4;
$blue100: #a3e9ff33;
$blue500: #00266b;
$purpleColor: #6e1048;
$footerBorderColor: rgba(224, 224, 224, 0.25);
$offerCardOverlay: rgba(0, 0, 0, 0.25);
$offerDetailsHeaderBackgroundColor: rgba(182, 32, 121, 0.1);

$primaryHeadingLightPinkColor: #e9d7fe;
$darkCaynBlue: #101828;
$lightGrayBorderColor: #e4e7ec;
$LightGrayishBlueColor: #f9fafb;

$text-xxl: 3.125rem;
$text-xl: 1.875rem;
$text-lg: 1.563rem;
$text-md: 1.25rem;
$text-base: 1.125rem;
$text-sm: 1rem;
$text-xs: 0.875rem;
$text-xxs: 0.75rem;
$text-xxxs: 0.625rem;
$font-black: 900;
$font-extrabold: 800;
$font-bold: 700;
$font-semibold: 600;
$font-medium: 500;
$font-normal: 400;
$font350: 350;
$font325: 325;
$font-light: 300;
$font-extralight: 200;
$font-thin: 100;
$line-height17: 17px;
