.grid {
  overflow: hidden;
  text-align: center; //   grid-template-columns: 1fr 1fr 1fr;
}

.middle {
  width: 100%;
  text-align: center;
  border-bottom: 1px dashed red;
  line-height: 0.1em;
  margin: 10px 0 20px;
  color: red;
  font-size: 10px;
}

.middle span {
  background: #fff;
  padding: 0 10px;
}

.login_drawer_container {
  height: 93vh;
}
