@import "../../../theme/variables.scss";

.confirmation-page-container {
  .cnfrm-strt {
    max-width: 80rem;
    // min-height: 100vh;

    .pnr-box {
      width: 15%;
      height: 7rem;
      background: rgba(180, 255, 185, 0.23);
      border: 1px solid $green700;
      border-radius: 5px;
    }

    .pnr-box-failure {
      width: 15%;
      height: 7rem;
      background: rgba(235, 13, 13, 0.23);
      border: 1px solid red;
      border-radius: 5px;
    }

    .pnr-fail {
      width: 15%;
      height: 7rem;
      background: rgba(181, 89, 72, 0.23);
      border: 1px solid red;
      border-radius: 5px;
    }
  }

  .imp-msg {
    max-width: 80rem;
    height: auto;
    background: rgba(196, 196, 196, 0.15);
    line-height: 27px;
    padding-left: 30px !important;
  }

  .tabs-box-flight-confirm {
    max-width: 80rem;
    margin: auto;
    margin-top: 2rem;

    #simple-tab-0,
    #simple-tab-1 {
      border: 1px solid rgba(0, 0, 0, 0.12);
      margin-right: 4px;
    }

    .active-tab-custom {
      background: $whiteColor !important;
      color: $primaryColor !important;
      border-bottom: none !important;
    }
  }

  .traveller-info-confirm {
    max-width: 80rem;
    min-height: 10rem;

    .traveller-info-confirm-header {
      min-height: 45px;
      width: 100%;
    }
  }

  .btn-group-confirm {
    button {
      border: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      height: 3rem;
      margin-right: 2rem;
      padding: 0.5rem 2rem;
    }
  }
}
