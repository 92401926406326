@import "../../../../theme/stylesheet.scss";

.flight-option {
  max-width: 75rem;
  min-height: 133px;
  background: $whiteColor;

  .flight-cities {
    width: 100%;
    max-width: 446px;
    position: relative;

    .depart-city-info,
    .arrival-city-info {
      .p3,
      .p2 {
        color: $blue500;
      }
    }
  }

  .flight-duration {
    max-width: 154px;
    position: relative;
    flex-direction: column !important;
  }
  .iternary {
    max-width: 154px;
    flex-direction: column !important;
  }

  .flight-type-box {
    width: 12rem;
    height: 9rem;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: -3px -1px 15px rgba(0, 0, 0, 0.1);

    #header-biglite {
      height: 35px;
    }
    #header-bigeasy {
      background: $purpleColor;
      height: 35px;
    }
    .seats-avlbl {
      width: 10rem;
      height: 1.8rem;
      background-color: $pinkBgColor;
      padding: 4px;
    }
    .biglite-info,
    .bigeasy-info {
      height: 65%;
    }

    .btn-select-flight {
      width: 70%;
      border: none;
      padding: 5px;
      margin-bottom: 0.5rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
    }

    .btn-select-flight-success {
      background-color: rgb(56, 176, 56);
    }
  }
}
.flight-option-tab-view {
  max-width: 54.313rem;
}

.flight-cities:after,
// .flight-duration:after {
//   content: "";
//   display: block;
//   position: absolute;
//   bottom: 15%;
//   right: -25%;
//   height: 70%;
//   width: 1px;
//   background: rgba(0, 0, 0, 0.12);
// }

.connect-logo {
  position: relative;
}

.connect-logo:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -142%;
  right: 50%;
  height: 400%;
  width: 1px;
  background: rgba(0, 0, 0, 0.25);
  transform: rotate(90deg);
}

.flight-stops {
  width: 40%;
}

#biglite-id {
  height: 11rem;

  .biglite-info,
  .bigeasy-info {
    height: 80%;
  }
}

@media only screen and (min-device-width: 800px) and (max-device-width: 1100px) {
  .connect-logo:after {
    content: "";
    display: block;
    position: absolute;
    height: 0%;
  }
}
