// .flight_start {
//   position: absolute;
//   top: -7px;
// }

// .flight_end {
//   position: absolute;
//   top: -5px;
//   right: 0px;
// }

.flight_start {
  position: absolute;
  top: -7px;
  right: 0px;
}

.flight_end {
  position: absolute;
  top: -5px;
}

.flight_center {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}
