@import "../../../../theme/variables.scss";
.flight-details-wrapper {
  max-width: 78rem;
  height: 17.5rem;

  .flight-details-header {
    width: 100%;
    height: 3rem;
    line-height: 3rem;
  }

  .flght-dtls-cross-svg-section {
    margin-top: 1rem;
    .border-line {
      .line {
        display: block;
        position: absolute;
        background-color: rgb(0, 0, 0);
        height: 1px;
        width: 75%;
        left: 8%;
      }
      .flght-dtls-cross-svg {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .dot-before {
        display: block;
        width: 10px;
        height: 10px;
        background-color: $primaryColor;
        border-radius: 50%;
        top: -5px;
        left: 8%;
        z-index: 1;
      }
      .dot-after {
        display: block;
        width: 10px;
        height: 10px;
        background-color: $primaryColor;
        border-radius: 50%;
        top: -5px;
        right: 17%;
        z-index: 1;
      }
    }
  }
}
