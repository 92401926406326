@import '../../theme/variables.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  font-size: 1rem;
  font-weight: 350;
}

.container {
  width: 100%;
  height: 100%;
  max-height: 46.125rem;
  position: relative;
  .header {
    margin: auto;
    .header-info {
      position: absolute;
      text-align: right;
      right: 20px;
      top: 10px;
      color: $whiteColor;
    }
  }

  main {
    .banner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem auto auto auto;
      width: 85%;
      max-width: 85rem;

      .banner-info {
        font-weight: 400;
        text-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
          0px 1px 2px rgba(16, 24, 40, 0.06), 0px 0px 0px #f4ebff;

        & span {
          color: $primaryColor;
        }
      }

      .aeroplane-pic {
        width: 30%;
        min-width: 15rem;
        height: auto;
      }
    }

    .home-banner-carousel {
      .slick-prev,
      .slick-next {
        z-index: 20;
        width: 40px !important;
        height: 40px !important;
        border: none !important;
      }
      .slick-prev {
        left: 15px;
      }
      .slick-next {
        right: 40px;
      }
      .slick-slide {
        height: 500px;
      }

      .slick-slide img {
        height: 500px;
      }
    }

    .tabs-box {
      margin: auto;
      width: 90%;
      max-width: 82.688rem;
      position: absolute;
      top: 75%;
      left: 50%;
      transform: translate(-50%, -50%);

      #simple-tab-0,
      #simple-tab-1,
      #simple-tab-2 {
        max-width: 9.5rem;
        height: 2.5rem;
        background: $whiteColor;
        color: #787885;
        box-shadow: inset 0px -3px 5px rgba(0, 0, 0, 0.09);
        border-radius: 0.625rem 0.625rem 0px 0px;
        margin-right: 2px;

        font-weight: 700;
        font-size: 0.75rem;
        line-height: 125%;
      }

      .active-tab-custom {
        background: $primaryColor !important;
        color: $whiteColor !important;
      }
    }
  }
  .flybig-alerts {
    width: 90%;
    max-width: 82.688rem;
    height: 5.125rem;
    background: $whiteColor;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.11);
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: auto;
    display: flex;
    overflow: hidden;
    align-items: center;
    margin-top: 10rem;
    .alerts-left {
      min-width: 13.375rem;
      height: 5.125rem;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      color: $whiteColor;
      background: $primaryColor url('../../assets/svgs/stripes.svg');
    }
  }
}

.popular-routes-box {
  width: 100%;
  max-width: 90rem;
  margin: 8rem auto auto auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .carousel-popular-routes {
    width: 100%;
    max-width: 90rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .carousel-slider {
      width: 90%;
      max-width: 75rem;
      margin: auto;
      padding: 0 2%;
    }
  }
  .popular-routes-card {
    // box-shadow: 0px 4px 20px rgba(189, 189, 189, 0.31);
    border-radius: 8px;
  }
}

.special-offers {
  width: 100%;
  max-width: 90rem;
  margin: 2rem auto auto auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .carousel-slider {
    width: 90%;
    max-width: 75rem;
    margin: auto;
    padding: 0 2%;
  }
}

.marquee-text {
  font-weight: 400;
  font-size: 1rem;
}

.travel-planning {
  width: 100%;
  max-width: 90rem;
  margin: 5rem auto auto auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .grid-container {
    width: 90%;

    .grid-img-big {
      img {
        width: 100%;
        max-width: 41rem;
        height: 520px;
        // height: 80%;
      }
    }
    .grid-img:nth-of-type(1),
    .grid-img:nth-of-type(2),
    .grid-img:nth-of-type(3),
    .grid-img:nth-of-type(4) {
      img {
        width: 100%;
        max-width: 19.938rem;
        height: 280px;
      }
    }
    & #flex-grid-fig-0 {
      margin-bottom: 11px;
    }
  }
}

.news-section {
  width: 90%;
  max-width: 80rem;
  margin: 5rem auto auto auto;

  .purple-bullet {
    width: 13px;
    height: 42px;
    background: $primaryColor;

    p {
      margin-left: 2rem;
      padding-top: 4px;
    }
  }

  .news-grid {
    margin: 1rem auto auto auto;
    overflow: hidden;
  }
}

.passenger-quantity {
  width: 10px;
}

@media screen and (min-width: 1200px) {
  .banner-info {
    font-size: 3.125rem;
  }
  .flybig-alerts {
    margin-top: 10rem !important;
  }
}
@media only screen and (min-device-width: 600px) and (max-device-width: 1200px) {
  .banner-info {
    font-size: 2.34rem;
  }
  .flybig-alerts {
    margin-top: 1rem !important;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 1000px) {
  .banner-info {
    font-size: 1.56rem;
  }
}

@media only screen and (min-device-width: 0px) and (max-device-width: 640px) {
  .container {
    display: none;
  }
  .popular-routes-box {
    margin: 3rem auto auto auto;
  }
}
