@import "../../../../theme/variables.scss";
.flight-details {
  max-width: 75rem;
  border-top: none;
  .sub-header {
    width: 100%;
    background-color: $gray200;
    height: 2.5rem;
    font-size: 0.875rem;
    .sp1 {
      margin-left: 15%;
    }
  }

  .biglite-info {
    .bglite {
      width: 10%;
      max-width: 6rem;
      height: 4.75rem;
    }
  }
  .bigeasy-info {
    .bgeasy {
      width: 10%;
      max-width: 6rem;
      height: 4.75rem;
    }
  }
}

.sp1-bg {
  margin-left: 9%;
}
