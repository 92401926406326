.btn-send-otp {
  width: 8rem;
  height: 2.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.otp-code-input {
  width: 4rem;
  height: 2.5rem;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  margin: 0.5rem;
  padding: 0.5rem;
}

.btn-confirm-otp {
  width: 10rem;
  height: 2.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.otp-input {
  input {
    height: 45px !important;
    width: 35px !important;
    border-radius: 6px;
    border: 1px solid darkgray;
  }
}
