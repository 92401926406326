.marginBottom30 {
  margin-bottom: 30px;
}
.marginLeft30 {
  margin-left: 30px;
}

.centerText {
  text-align: center;
}

.accordianContainer {
  box-shadow: unset !important;
  margin: 24px 0px;

  .summary {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
  }
  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
    .tableContainer {
      margin-bottom: 30px;
      .tableHeading {
        background-color: #b62079;
      }
      .columnHead {
        color: white;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        font-family: "GothamBook";
      }
      .columnBody {
        line-height: 24px;
        font-size: 18px;
        font-family: "GothamBook";
        color: #667085;
      }
    }
  }
}

.accordianWrapper {
  border-radius: 66%;
  border: 1px solid;
  width: 24px;
  height: 24px;
  text-align: center;
  color: #b62079;
}

@media only screen and (min-width: 768px) {
  /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
  /* phones */
  .accordianContainer {
    width: 100%;
  }
}
