@import "../../theme/variables.scss";

.faq_Container {
  .faq_getInTouchContainer {
    background: $LightGrayishBlueColor;
    border-radius: 16px;
    padding: 32px;
    margin: 80px 0 300px 0 !important;

    .faq_getInTouchHeader {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: $darkCaynBlue;
      margin-bottom: 8px;
    }
    .faq_getInTouchDescription {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: $gray900;
    }
    .faq_buttonContainer {
      text-align: end;
      .faq_getInTouchBtn {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $whiteColor;
        background-color: $primaryColor;
        padding: 10px 18px;
        border: 1px solid $primaryColor;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
      }
    }
  }
}

.faq_bannerImage img {
  align-items: center;
  width: 100%;
  padding: 2rem;
  justify-content: flex-end;
  background-size: 100%;
  padding: 0px;
  height: 240px;
  margin-bottom: 30px;
}

.faqHeading {
  color: #101828;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
}
.faqSubHeading {
  color: #667085;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
  a {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 767px) {
  /* phones */
  .faq_bannerImage {
    height: auto;
    img {
      height: 75px;
    }
  }
}
