@import "../../theme/variables.scss";

footer {
  background-color: $footerColor;
  min-height: 15rem;
  display: flex;
  flex-direction: column;

  .footer-logo-div {
    width: 100%;
    max-width: 90rem;
    margin: auto;
    .footer-logo {
      margin: 0.5rem auto 0.5rem 10%;
    }
  }

  .footer-content {
    max-width: 90rem;
    margin: auto;
    padding: 1rem 2rem;
    display: flex;
    flex-wrap: wrap;
    color: $gray800;

    .footer-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 3rem;
      a {
        font-weight: 100;
        margin-top: 7px;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .download-column {
      display: flex;
      align-items: center;
      img {
        margin: 1rem;
      }
    }
  }

  .copyright-info {
    color: $whiteColor;
    width: 100%;
    // max-width: 75rem;
    margin: auto;
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    background-color: #b62079;
    p {
      font-weight: 400;
      font-size: 14px;
    }

    .footer-social-icons {
      padding: 15px;
      img {
        margin-right: 1rem;
      }
    }
  }
  @media screen and (max-width: 640px) {
    .footer-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      padding: 1rem;
      .footer-column {
        margin: 0px;
      }
    }
    .footer-logo-div {
      .footer-logo {
        margin: 1rem auto 1rem 1rem;
      }
    }
  }
}
