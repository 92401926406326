@import "../../../theme/stylesheet.scss";
.my-bookings-container {
  .tabs-box-my-bookings-tabs {
    border-bottom: 1px solid gray;

    .simple-tab-0-bookings,
    .simple-tab-1-bookings {
      border: 1px solid black;
      font-family: "gotham rounded" !important;
      font-weight: 600;
      font-size: 14px;
      margin-right: 1rem !important;
    }

    .active-tab-custom-booking {
      color: $primaryColor !important;
      border-bottom: none !important;
    }
  }

  .my-bookings-tab-wrapper {
    .search-pnr-box {
      min-height: 8rem;
    }
  }
}

.my-bookings-main-tabpanel {
  margin-left: -1.5rem !important;
}

.my-booking-tabpanel {
  margin-left: -1.5rem !important;
}

.tabs-box-booking-tabs {
  border-bottom: 1px solid black;
  #simple-tab-0-bookings,
  #simple-tab-1-bookings,
  #simple-tab-2-bookings {
    border-bottom: none;
    font-family: "gotham rounded";
    font-weight: 600;
    font-size: 14px;
  }

  .active-tab-custom-booking {
    color: $primaryColor !important;
  }
}
