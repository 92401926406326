@import "../../theme/variables.scss";

.aboutUsContainer {
  .aboutus_bannerImage img {
    align-items: center;
    width: 100%;
    padding: 2rem;
    background-repeat: no-repeat;
    justify-content: flex-end;
    background-size: 100%;
    padding: 0px;
    height: 550px;
  }

  .aboutus_bannerText {
    color: #667085;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin: 20px 0 0 32px;
    text-align: left;
  }

  .aboutus_locations {
    text-align: left;
    padding: 0 64px 96px 80px;
    width: 105% !important;
    max-width: unset;
  }

  .aboutus_cardHeading {
    text-align: left;
    font-size: 24px;
    line-height: 38px;
    color: $darkCaynBlue;
    width: 100%;
    margin-bottom: 16px;
  }

  .aboutus_cardInnerText {
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    color: $gray900;
  }

  .aboutus_pageHeading {
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    width: 100%;
    text-align: left;
    // margin: 30px 1rem;
  }
}

@media only screen and (min-width: 768px) {
  /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
  /* phones */
  .aboutUsContainer {
    .aboutus_bannerImage {
      img {
        height: 140px;
      }
    }
    .aboutus_bannerText {
      font-size: 20px;
      margin: 20px 0 0 0px;
    }

    .aboutus_locations {
      text-align: left;
      padding: 0 10px;
      margin-left: 0px !important;
      max-width: unset;
    }

    .aboutus_cardHeading {
      font-size: 20px;
    }
    .aboutus_pageHeading {
      margin: 0px;
    }
  }
}
