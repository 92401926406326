@import "../../../../../theme/variables.scss";

.flight_card_properties {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.flight_card_properties:nth-child(n + 4) {
  border-bottom: none;
}

.flight_card_properties:nth-child(3n) {
  border-right: none;
}

.price_radio_button {
  height: 18px;
  width: 18px;
  accent-color: $primaryColor;
}
