@import "../../../../theme/variables.scss";

.flight-search {
  width: 90%;
  max-width: 75rem;
  height: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .input-custom {
    background: $whiteColor;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    height: 3rem;
  }
  .radio-btn-grp {
    border-right: 1px solid $grayBorderColor;
  }

  .modify-search-btn {
    .btn-modify-search {
      background: #b62079;
      color: $whiteColor;
      border: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      width: 100%;
      height: 2.8rem;
      margin-top: 20px;
    }
    .btn-modify-search:disabled {
      background-color: $gray600;
      cursor: not-allowed;
    }
  }
}

.css-1pa58rh-MuiInputBase-root-MuiInput-root {
  font-family: "gothamBook" !important;
}

@media screen and (min-width: 1200px) {
  .btn-modify-search {
    margin-top: 1rem;
  }
}
@media only screen and (min-device-width: 800px) and (max-device-width: 1200px) {
  .passanger {
    margin-left: 56% !important;
  }

  .btn-modify-search {
    margin-top: 1.2rem;
  }
}
