@import "../../theme/variables.scss";


.offers_container{
  .offers_Heading{
    color: #101828;
    font-weight: 600;
    font-size: 30px;
    line-height: 44px;
    font-weight: 800;
    
  }
  .offers_SubHeading{
    color: #667085;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin-top: 20px;
    a{
      text-decoration: underline;
    }
  }
}
.offers_bannerImage {
  align-items: center;
  width: 100%;
  padding: 2rem;
  background: url(../../assets/images/faqBanner.png) no-repeat;
  justify-content: flex-end;
  background-size: 100%;
  padding: 0px;
  height: 240px;
}


@media only screen and (min-width: 768px) {
  /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
  /* phones */

  .offers_bannerImage {
    height: 75px;
  }
}
