@import "../../theme/variables.scss";
.contactus {
  .contactus_grievanceRedressal {
    padding: 60px 0px 60px 0px;
    line-height: 24px;
    border-bottom: 1px solid #e4e7ec !important;
  }
  .contactus_pageHeading {
    font-weight: 600;
    font-size: 30px;
    margin-top: 30px;
  }
  .contactus_heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: $darkCaynBlue;
  }
  .contactus_SubHeading {
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    color: $gray900;
  }
  .contactus_feedbackborder {
    border-bottom: 1px solid #e4e7ec !important;
    border: none;
    margin-top: 40px;
    padding: 0px 30px 33x 30px;
  }

  .textColor {
    color: $primary700;
  }
  .link {
    color: $link500;
  }
  .contactus_bannerImage img {
    align-items: center;
    width: 100%;
    padding: 2rem;
    background-repeat: no-repeat;
    justify-content: flex-end;
    background-size: 100%;
    padding: 0px;
    height: 240px;
  }
}

@media only screen and (max-width: 767px) {
  /* phones */
  .contactus {
    .contactus_feedbackborder {
      margin-top: 25px;
    }
    .contactus_bannerImage {
      height: 80px !important;
    }
  }
}
