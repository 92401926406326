@import "../../../../theme/variables.scss";

.offer_card {
  position: relative;
  box-sizing: border-box;
  padding: 0px;
  border-radius: 0px;

  .offer_imageOverlay {
    color: white;
    position: absolute;
    width: 100%;
    top: 51%;
    background: $offerCardOverlay;

    .offer_tnc{
      position: absolute;
      right: 10px;
      top: 3px;
      font-size: 12px;
    }
    .offer_expiryDate{
      margin-left: 10px;
      top: 3px;
      font-size: 12px;
    }
  }

  .offer_title{
    font-family: 'Gotham Rounded';
    font-size: 25px;
    color: $link500;
  }

  .offer_description{
    font-family: 'Gotham Rounded';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $link500;
  }
  .offer_buttonContainer{
    flex-direction: row-reverse;
    .offer_grabButton{
      width: 80px;
      height: 23px;
      background: $primaryColor;
      box-shadow: 0px 4px 4px $offerCardOverlay;
      border-radius: 4px;
      font-family: 'Gotham Rounded';
      font-size: 12px;
      color: $whiteColor;
      padding: 0px;
      text-transform: unset;
      margin-right: 0 25px 20px 0;
    }
  }
  
}

@media only screen and (min-width: 768px) {
    /* tablets and desktop */
  }
  
  @media only screen and (max-width: 767px) {
    /* phones */
  }
  