@import "../../theme/variables.scss";

.fleet_Container {
  .fleet_BannerImage {
    align-items: center;
    padding: 2rem;
    background: url(../../assets/images/fleetBanner1.png) no-repeat;
    justify-content: flex-end;
    background-size: 100%;
    height: 430px;
  }
  .fleet_bannerTextOne {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    margin-bottom: 20px;
    text-align: left;
  }

  .fleet_Number {
    font-weight: 600;
    font-size: 60px;
    letter-spacing: -0.02em;
    color: $primaryColor;
  }
  .fleet_NumberDescription {
    font-weight: 500;
    font-size: 18px;
    color: $darkCaynBlue;
  }
  
  .fleet_cardHeading {
    text-align: left;
    font-size: 30px;
    line-height: 38px;
    color: $darkCaynBlue;
    width: 100%;
    margin-bottom: 16px;
  }

  .fleet_cardInnerText {
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    color: $gray900;
    margin-bottom: 60px;
  }

  .fleet_Description {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: $gray900;
    text-align: left;
    margin-bottom: 130px;
  }
}

@media only screen and (min-width: 768px) {
  /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
  /* phones */
  .fleet_Container {
    .fleet_BannerImage {
      padding: 10px;
      position: relative;  
      height: 115px;  
    }

    .fleet_cardHeading {
      font-size: 22px;
    }
    .fleet_Description {
      width: 100%;
    }
  }
}

@media (min-width: 1650px) and (max-width: 4000px)  {
  //insert styles here...
  .fleet_BannerImage{
    height: 500px;
  }
}
