.user_contact_details,
.mobile_passenger_container {
  height: 100%;
  .MuiTextField-root {
    width: 100%;
  }
}

.birthDay-datepicker {
  .datepicker {
    position: static !important;
  }
  .datepicker .datepicker-navbar {
    display: flex !important;
  }
}

.MuiDrawer-paperAnchorTop {
  height: 100vh !important;
}
