.login-container {
  width: 44rem !important;
  height: 90%;
  min-height: 30rem;

  .login-banner {
    width: 15rem;
    height: 100%;
    background: url("https://flybigassets.s3.ap-south-1.amazonaws.com/Webassets/loginCover.png")
      no-repeat;
    background-size: cover;
  }

  .auth-aside {
    width: 100%;
    height: 100%;
  }

  .signup-aside {
    width: 100%;
    height: 100%;
  }
}

.btn-login {
  height: 2.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: none;
}

.btn-google-signin {
  height: 2.5rem;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.signup-container {
  height: 90%;
}

.react-tel-input .form-control {
  width: 100%;
  height: 40px;
}

.google-btn {
  button {
    width: 100% !important;
    justify-content: center !important;
    font-family: Gotham Rounded !important;
    font-size: 18px !important;
  }
}

.my-facebook-button-class {
  min-height: 2.7rem;
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  cursor: pointer;

  svg {
    margin-right: 1rem;
  }
}
