@import "../../../theme/variables.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container-search-page {
  main {
    max-width: 75rem;
    margin: auto;
    .choose-your-flight {
      margin-top: 2rem;

      .week-month {
        margin-left: 82%;
        .btn-week,
        .btn-month {
          width: 81px;
          height: 36px;
        }
        .btn-month {
          background-color: $whiteColor;
        }
      }
      .dates-carousel-box {
        max-width: 75rem;
        .carousel-unit {
          opacity: 0.74;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          width: 90% !important;
          height: 60px;
          padding: 10px;
        }
      }
    }

    .all-flights {
      .onward-heading {
        width: 100%;
        max-width: 1200px;
        margin: 2rem auto 1rem auto;
      }
    }
  }
}

.skeleton-flight-result-desk {
  width: 100%;
  height: 100%;
  line-height: 1;
}
