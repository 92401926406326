@import "../../theme/variables.scss";

.offerDetails_section {
  .offerDetails_headerSection {
    .offerDetails_heading {
      color: $darkCaynBlue;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
    }
    .offerDetails_subHeading {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: $blackColor;

      padding: 20px 15px;
      background: $offerDetailsHeaderBackgroundColor
    }
  }
  .offerDetails_ol{
    padding: 0px 60px;
    .offerDetails_list {
        font-weight: 400;
        font-size: 20px;
        line-height: 50px;
        color: $gray900;
      }
  }
 
}
