.myProfile-container {
  .profile-aside {
    width: 18%;
    height: 100%;
  }

  .my-profile-form-div {
    width: 80%;
  }
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  backdrop-filter: blur(5px);
}
