


.faqCardLayout{
    box-shadow: none!important;
    .faqCardSubHeading {
      color: #667085;
      font-size: 16px!important;
      font-weight: 400!important;
  }
}
